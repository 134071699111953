import React from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/styles';

function TestingInTheSandbox() {
    const classes = useStyles();
    return (
        <div>
            <React.Fragment>
                <Typography color="primary" variant="h4" gutterBottom>Testing in the Sandbox</Typography>
                <Box variant="body1" gutterBottom>
                    <Box mb={4}>
                    To support the onboarding of AISPs and CBPIIs, we’ve set up a Sandbox reflective of our live APIs.
                    </Box>
                </Box>
                
                <Typography color="primary" variant="h5" gutterBottom>OAuth Provider endpoints for Sandbox</Typography>
                <Box mt={2} mb={4}>
                    <div><strong>Token :</strong> https://openbanking-snd.mywavecard.co.uk/oauth-api/oauth2/token</div>
                    <div><strong>Browser Authorisation : </strong> https://sandbox-auth.mywavecard.co.uk</div>
                    <div><strong>Authorise :</strong> https://openbanking-snd.mywavecard.co.uk/oauth-api/oauth2/authorize</div>
                    <div><strong>Well-known :</strong> https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_MthjOOfXy/.well-known/jwks.json</div>
                </Box>
                
                <Typography color="primary" variant="h5" gutterBottom>API endpoints for Sandbox</Typography>
                <Box mt={2}>
                    <div><strong>Account and Transaction :</strong> https://openbanking-snd.mywavecard.co.uk/aisp-api</div>
                    <div><strong>Confirmation of Funds :</strong> https://openbanking-snd.mywavecard.co.uk/cbpii-api</div>
                </Box>

                <Typography mt={4} color="primary" variant="h5" gutterBottom>How it works</Typography>
                <Box mt={2} mb={4}>
                    Using below set of parameters you can interact with our sandbox APIs. 
                </Box>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Parameter Name</strong></TableCell>
                                <TableCell><strong>Parameter Value</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.value}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
            </React.Fragment>
        </div>
    )
}

function createData(name, value) {
    return { name, value };
}

const rows = [
    createData('client_id', '3dkohmav55vejclbjpuiamjckl'),
    createData('client_secret', '1cmbg1el325gk07euup5jcoifo8fdsr62k4bdmiejpgnm8irhkjs'),
    createData('Username', 'ob-testuser'),
    createData('Passcode', '12345'),
    createData('Vericode', '123456'),
    createData('Account ID', '55571400000099534281'),
    
];

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default TestingInTheSandbox
