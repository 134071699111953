import React, { useEffect } from "react";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SwaggerUI from 'swagger-ui';

function OAuth() {
    
    useEffect(() => {
        SwaggerUI({
            domNode: document.getElementById("oauth-api-data"),
            url: "https://api.swaggerhub.com/apis/ChetwoodFinancial/oauth-api/V0.2?resolved=true"
          })
    });
    return (
        <div>
            <React.Fragment>
                <Typography color="primary" variant="h4" gutterBottom>OAuth API</Typography>
                <Box variant="body1" gutterBottom>
                    <Box mb={2}>
                    Our Open Banking APIs use the OAuth 2.0 protocol to authorise calls. OAuth is an open standard that provides secure access to protected resources. Wave's authorisation server issues three types of tokens (Access Token, Refresh Token, ID Token).
                    </Box>
                    <Typography color="primary" variant="h5" gutterBottom>Endpoints</Typography>
                    <Box mt={2} mb={4}>
                        <div><strong>Sandbox :</strong> https://openbanking-snd.mywavecard.co.uk/oauth-api</div>
                        <div><strong>Production :</strong> https://openbanking-prod.mywavecard.co.uk/oauth-api</div>
                    </Box>
                    <Typography color="primary" variant="h5" gutterBottom>Interface Details</Typography>
                    <Box mb={2}>
                        <div id="oauth-api-data">
                        </div>
                    </Box>
                </Box>
            </React.Fragment>
        </div>
    )
}

export default OAuth
