import React, { useEffect } from "react";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SwaggerUI from 'swagger-ui';

function ConfirmationOfFunds() {
    
    useEffect(() => {
        SwaggerUI({
            domNode: document.getElementById("cbpii-api-data"),
            url: "https://api.swaggerhub.com/apis/ChetwoodFinancial/cbpii-api/V0.1?resolved=true"
          })
    });
    return (
        <div>
            <React.Fragment>
                <Typography color="primary" variant="h4" gutterBottom>Confirmation of Funds</Typography>
                <Box variant="body1" gutterBottom>
                    <Box mb={4}>
                    Our Confirmation of Funds API enables CBPIIs to receive a yes or no response to whether a Wave customer has sufficient funds for a specific transaction.
                    </Box>
                    <Typography color="primary" variant="h5" gutterBottom>Endpoints</Typography>
                    <Box mt={2} mb={4}>
                        <div><strong>Sandbox :</strong> https://openbanking-snd.mywavecard.co.uk/cbpii-api</div>
                        <div><strong>Production :</strong> https://openbanking-prod.mywavecard.co.uk/cbpii-api</div>
                    </Box>
                    <Typography color="primary" variant="h5" gutterBottom>Interface Details</Typography>
                    <Box mb={2}>
                        <div id="cbpii-api-data">
                        </div>
                    </Box>
                </Box>
            </React.Fragment>
        </div>
    )
}

export default ConfirmationOfFunds
