import { red } from '@material-ui/core/colors';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

// A custom theme for this app
let theme = createTheme({
  palette: {
    primary: {
      main: '#0D444D',
    },
    secondary: {
      main: '#c94c00',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: ['"Montserrat"', 'Open Sans'].join(','),
    h4: {fontWeight:700,}
  },
});
theme = responsiveFontSizes(theme);
export default theme;