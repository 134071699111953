import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import HomeIcon from '@material-ui/icons/Home';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { makeStyles } from '@material-ui/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Divider } from '@material-ui/core';
import CodeIcon from '@material-ui/icons/Code';
const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
})); 

const Sidebar = props => {
    const [open, setOpen] = React.useState(true);

    const handleMenuClick = () => {
      setOpen(!open);
    };
    
    const classes = useStyles();

  return (
    <div>
        <ListItem button component="a" href="/">
            <ListItemIcon>
              <HomeIcon color="primary" />
            </ListItemIcon>
            <ListItemText color="primary" primary="Home" />
        </ListItem>
        <ListItem button component="a" href="/registration">
            <ListItemIcon>
              <AssignmentIcon color="primary" />
            </ListItemIcon>
            <ListItemText color="primary" primary="Registration" />
        </ListItem>
        <ListItem button onClick={handleMenuClick}>
            <ListItemIcon>
              <PermIdentityIcon color="primary" />
            </ListItemIcon>
            <ListItemText color="primary" primary="Authentication" />
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <ListItem button className={classes.nested} component="a" href="/customer-authorisation">
              <ListItemText primary="Customer Authorisation"  />
            </ListItem>
            <ListItem button className={classes.nested} component="a" href="/get-access-token">
              <ListItemText primary="Get an access token" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button component="a" href="/testing-in-the-sandbox">
            <ListItemIcon>
              <CodeIcon color="primary" />
            </ListItemIcon>
            <ListItemText color="primary" primary="Testing in the Sandbox" />
        </ListItem>
        <ListItem button component="a" href="/support">
            <ListItemIcon>
              <ContactSupportIcon color="primary" />
            </ListItemIcon>
            <ListItemText color="primary" primary="Support" />
        </ListItem>
        <Divider/>
        <ListItem button component="a" href="/account-and-transactions">
            <ListItemIcon>
            < CreditCardIcon color="primary" />
            </ListItemIcon>
            <ListItemText color="primary" primary="Account and Transactions API" />
        </ListItem>
        <ListItem button component="a" href="/confirmation-of-funds">
            <ListItemIcon>
              <CheckBoxIcon color="primary" />
            </ListItemIcon>
            <ListItemText color="primary" primary="Confirmation of Funds API" />
        </ListItem>
        <ListItem button component="a" href="/oauth-api">
            <ListItemIcon>
              <VpnKeyIcon color="primary" />
            </ListItemIcon>
            <ListItemText color="primary" primary="OAuth API" />
        </ListItem>
    </div>
  )
}

export default Sidebar;