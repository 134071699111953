import React from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function CustomerAuthorization() {
    return (
        <div>
            <React.Fragment>
                    <Typography color="primary" variant="h4" gutterBottom>Customer Authorisation</Typography>
                    <Box variant="body1" gutterBottom>
                        Get an access token is a two-step process:
                        <Box m={2}>
                            <div>1. Redirect the customer to Wave to authorise your app</div>
                            <div>2. Once authorised by the customer, Wave then redirects them back to your app with an authorisation code</div>
                        </Box>
                        <Box mb={4}>
                        If you request the access token from Wave before the two steps above have been completed, the access token won’t have permission to access the customer’s information.
                        </Box>
                        <Typography color="primary" variant="h5" gutterBottom>Redirect the user to Wave</Typography>
                        <Box mt={2}>
                        Direct the customer to the Wave website, where they’ll log in and confirm they wish to share access to account information with you.
                        </Box>
                        <pre>
                        <code><div>https://sandbox-auth.mywavecard.co.uk/?</div>
                            <div className="ml30">
                                <div>client_id=<span className="nv">$client_id</span><span className="s2">&amp;</span></div>
                                <div>redirect_uri=<span className="nv">$redirect_uri</span><span className="s2">&amp;</span></div>
                                <div>response_type=code&amp;</div>
                                <div>scope=accounts<span className="s2">&amp;</span></div>
                                <div>state=<span className="nv">$state_token</span></div>
                            </div>
                        </code>
                        </pre>
                        
                        <Box mt={2} mb={4}>
                        <Typography variant="h6" gutterBottom>
                            URL Arguments
                        </Typography>
                        <table className="paramsTable">
                            <tbody>
                                <tr>
                                <td><code className="prettyprint">client_id</code>
                                <Typography className="notice" variant="caption" display="block" gutterBottom>Required</Typography>
                                </td>
                                <td>Your client ID.</td>
                                </tr>
                                <tr>
                                <td><code className="prettyprint">redirect_uri</code>
                                <Typography className="notice" variant="caption" display="block" gutterBottom>Required</Typography>
                                </td>
                                <td>A URI to which users will be redirected after authorising your app.</td>
                                </tr>
                                <tr>
                                <td><code className="prettyprint">response_type</code>
                                <Typography  className="notice" variant="caption" display="block" gutterBottom>Required</Typography></td>
                                <td>Must be set to <b>code</b>.</td>
                                </tr>
                                <tr>
                                <td><code className="prettyprint">state</code></td>
                                <td>An unguessable random string used to protect against <a rel="noreferrer" target="_blank" href="https://www.owasp.org/index.php/Cross-Site_Request_Forgery_(CSRF)">cross-site request forgery attacks</a>.</td>
                                </tr>
                            </tbody>
                            </table>
                        </Box>

                        <Typography color="primary" variant="h5" gutterBottom>Wave redirects back to your app</Typography>
                        <Box mt={2} mb={2}>
                        If the customer:
                        </Box>
                        <Box m={2}>
                            <div>- Allows access to their account, Wave redirects them back to your app with an authorisation code.</div>
                            <div>- Changes their mind and doesn’t grant you access, Wave redirects back to your app but no authorisation code is provided.</div>
                        </Box>
                        <pre>
                        <code><div>https://your.example.com/oauth/callback?</div>
                            <div className="ml30">
                                <div>code=<span className="nv">$authorisation_code</span><span className="s2">&amp;</span></div>
                                <div>state=<span className="nv">$state_token</span><span className="s2">&amp;</span></div>
                            </div>
                        </code>
                        </pre>
                       
                        <Box mt={2} mb={4}>
                        <Typography variant="h6" gutterBottom>
                            URL Arguments
                        </Typography>
                        <table className="paramsTable">
                            <tbody>
                                <tr>
                                <td><code className="prettyprint">code</code>
                                </td>
                                <td>A temporary authorization code which will be exchanged for an access token in the next step.</td>
                                </tr>
                                <tr>
                                <td><code className="prettyprint">state</code>
                                </td>
                                <td>The same string you provided as state when sending the user to Wave. If this value differs from what you sent, you must abort the authentication process.</td>
                                </tr>
                            </tbody>
                            </table>
                        </Box>

                        <Typography color="primary" variant="h5" gutterBottom>Authenticating requests</Typography>
                        <Box mt={2}>
                        <b>All</b> requests must be authenticated with an access token supplied in the Authorization header using the Bearer scheme. Your client may only have one active access token at a time, per user. Acquiring a new access token will invalidate any other token you own for that user.
                        </Box>

                    </Box>
                </React.Fragment>
        </div>
    )
}

export default CustomerAuthorization
