import React from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function Registration() {
    return (
        <div>
            <React.Fragment>
                    <Typography color="primary" variant="h4" gutterBottom>How to register</Typography>
                    <Box variant="body1" gutterBottom>
                        <Box mb={2}>
                        You need to register in order to work with our Open Banking APIs. Once registered and depending on your permissions you will be able to access the:
                        </Box>
                        <Box mb={2}>
                            <div>- Account and Transaction API</div>
                            <div>- Confirmation of Funds API</div>
                        </Box>
                    </Box>
                    <Typography mt={4} mb={2} color="primary" variant="h5" gutterBottom>Are you registered with the Financial Conduct Authority?</Typography>
                    <Box variant="body1" gutterBottom>
                        <Box mb={2}>
                        To access our live Open Banking APIs in production you first need to be registered with the Financial Conduct Authority (FCA) as an authorised AISP or CBPII. This requires approval from the FCA or an equivalent regulator.
                        </Box>
                    </Box>

                    <Typography mt={4} mb={2} color="primary" variant="h5" gutterBottom>Register an app</Typography>
                    <Box variant="body1" gutterBottom>
                        <Box mb={2}>
                        Once registered with the FCA as an authorised AISP or CBPII you then can contact our <a href="mailto:support@mywavecard.co.uk">support team</a> to register your app with Wave.
                        </Box>
                        <Box mb={2}>
                        You will receive your Wave client ID and a client secret.
                        </Box>
                    </Box>
                    
                    <Typography mt={4} mb={2} color="primary" variant="h5" gutterBottom>Our sandbox</Typography>
                    <Box variant="body1" gutterBottom>
                        <Box mb={2}>
                        If you’re new to Open Banking or if you’re using a version of our APIs for the first time you can get started by <a href="/testing-in-the-sandbox">Testing in the Sandbox</a>. This gives you read/write access to Sandbox versions of our APIs using realistic sample data.
                        </Box>
                        <Box mb={2}>
                        To use our live APIs, you’ll need to be registered with the FCA for production action and then also with us.
                        </Box>
                    </Box>

                </React.Fragment>
        </div>
    )
}

export default Registration
