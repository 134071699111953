import React from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function GetAccessToken() {
    return (
        <div>
            <React.Fragment>
                    <Typography color="primary" variant="h4" gutterBottom>Get an access token</Typography>
                    <Box variant="body1" gutterBottom>
                    <Box mb={4}>
                    When you receive an authorisation code, you can then exchange it for an access token. The access token will be tied to both your client ID and the individual Wave customer, and is valid for several hours.
                        </Box>
            
                        <pre>
                        <code><div>http --form POST https://openbanking-snd.mywavecard.co.uk/oauth-api/oauth2/token?</div>
                            <div className="ml30">
                                <div>grant_type=<span className="nv">$grant_type</span><span className="s2">&amp;</span></div>
                                <div>client_id=<span className="nv">$client_id</span><span className="s2">&amp;</span></div>
                                <div>client_secret=<span className="nv">$client_secret</span><span className="s2">&amp;</span></div>
                                <div>redirect_uri=<span className="nv">$redirect_uri</span><span className="s2">&amp;</span></div>
                                <div>code=<span className="nv">$authorisation_code</span></div>
                            </div>
                        </code>
                        </pre>
                        
                        <Box mt={2} mb={4}>
                        <Typography variant="h6" gutterBottom>
                            Request Arguments
                        </Typography>
                        <table className="paramsTable">
                            <tbody>
                                <tr>
                                <td><code className="prettyprint">grant_type</code>
                                <Typography className="notice" variant="caption" display="block" gutterBottom>Required</Typography>
                                </td>
                                <td>This must be set to <b>authorization_code</b></td>
                                </tr>
                                <tr>
                                <td><code className="prettyprint">client_id</code>
                                <Typography className="notice" variant="caption" display="block" gutterBottom>Required</Typography>
                                </td>
                                <td>The client ID you received from Wave.</td>
                                </tr>
                                <tr>
                                <td><code className="prettyprint">client_secret</code>
                                <Typography className="notice" variant="caption" display="block" gutterBottom>Required</Typography>
                                </td>
                                <td>The client secret which you received from Wave.</td>
                                </tr>
                                <tr>
                                <td><code className="prettyprint">redirect_uri</code>
                                <Typography className="notice" variant="caption" display="block" gutterBottom>Required</Typography>
                                </td>
                                <td>A URI to which users will be redirected after authorising your app.</td>
                                </tr>
                                <tr>
                                <td><code className="prettyprint">code</code>
                                <Typography  className="notice" variant="caption" display="block" gutterBottom>Required</Typography></td>
                                <td>The authorization code you received when the user was redirected back to your app.</td>
                                </tr>
                            </tbody>
                            </table>
                        </Box>

                        <Typography color="primary" variant="h5" gutterBottom>Authenticating requests</Typography>
                        <Box mt={2}>
                        <b>All</b> requests must be authenticated with an access token supplied in the Authorisation header using the Bearer scheme.  
                        </Box>
                        <Box mt={2}>
                        Your client ID can only have one access token per customer at a time. Acquiring a new access token will invalidate any other token you own for that customer.
                        </Box>

                    </Box>
                </React.Fragment>
        </div>
    )
}

export default GetAccessToken
