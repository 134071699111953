import React from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function Support() {
    return (
        <div>
            <React.Fragment>
                    <Typography color="primary" variant="h4" gutterBottom>Support</Typography>
                    <Box variant="body1" gutterBottom>
                        <Box mb={2} mt={2}>
                        Please <a href="mailto:support@mywavecard.co.uk">get in touch</a> with us if you experience an issue with any of our Open Banking services.
                        </Box>
                    </Box>
                    <Typography mt={4} mb={2} color="primary" variant="h4" gutterBottom>Service status</Typography>
                    <Box variant="body1" gutterBottom>
                        <Box mb={2}>
                        Everything looks good.
                        </Box>
                    </Box>
                </React.Fragment>
        </div>
    )
}

export default Support
