import React, { useEffect } from "react";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SwaggerUI from 'swagger-ui';

function AccountAndTransactions() {
    
    useEffect(() => {
        SwaggerUI({
            domNode: document.getElementById("aisp-api-data"),
            url: "https://api.swaggerhub.com/apis/ChetwoodFinancial/aisp-api/V0.1?resolved=true"
          })
    });
    return (
        <div>
            <React.Fragment>
                <Typography color="primary" variant="h4" gutterBottom>Account and Transactions</Typography>
                <Box variant="body1" gutterBottom>
                    <Box mb={4}>
                    Our Account and Transactions API enables AISPs to securely retrieve Wave customer account information and transaction data.
                    </Box>
                    <Typography color="primary" variant="h5" gutterBottom>Endpoints</Typography>
                    <Box mt={2} mb={4}>
                        <div><strong>Sandbox :</strong> https://openbanking-snd.mywavecard.co.uk/aisp-api</div>
                        <div><strong>Production :</strong> https://openbanking-prod.mywavecard.co.uk/aisp-api</div>
                    </Box>
                    <Typography color="primary" variant="h5" gutterBottom>Interface Details</Typography>
                    <Box mb={2}>
                        <div id="aisp-api-data">
                        </div>
                    </Box>
                </Box>
            </React.Fragment>
        </div>
    )
}

export default AccountAndTransactions
