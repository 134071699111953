import React from 'react'
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});

function Home() {
    const classes = useStyles();
    return (
        <div>
                <React.Fragment>
                    <Typography color="primary" variant="h4" gutterBottom>Wave Open Banking</Typography>
                    <Typography variant="body1" gutterBottom mb={2}>
                    By working together and taking advantage of APIs, banks and other online service providers can leverage their complementary strengths to provide a great user experience, with new digital services offered across a greater number of touchpoints. This portal provides information for developers looking to harness our Open Banking capability.
                    </Typography>
                    <Typography variant="body1" gutterBottom mb={2}>
                    The Wave Open Banking Interface allows authorised Third Party Providers to access our secure APIs.
                    </Typography>
                    <Typography variant="body1" gutterBottom mb={2}>
                    Our implementation follows the UK Open Banking specification, available at www.openbanking.org.uk. To use the interface you must be registered with the Financial Conduct Authority (FCA) as either an Account Information Service Provider (AISP) or Card Based Payment Instrument Issuer (CBPII).
                    </Typography>
                    <Typography mt={4} color="primary" variant="h5" gutterBottom>What are Open Banking APIs?</Typography>
                    <Typography variant="body1" gutterBottom mb={2}>
                    Open Banking APIs expose a range of data to third party financial solution providers including payment initiators, account aggregators and other emerging fintechs. These APIs are designed and documented to support PSD2 and Open Banking regulations.
                    </Typography>
                    <Divider />
                    <Grid container spacing={1} mt={2} mb={4}>
                        <Grid item xs={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Account and Transactions
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Our Account and Transactions API enables AISPs to securely retrieve Wave customer account information and transaction data.
                                    </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions >
                                <Box mb={2}>
                                    <Button mt={2} variant="contained" size="small" color="primary" href="/account-and-transactions">
                                    Documentation
                                    </Button>
                                    </Box>
                                    {/* <Button size="small" color="primary">
                                    Learn More
                                    </Button> */}
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Confirmation of Funds
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Our Confirmation of Funds API enables CBPIIs to receive a yes/no response to whether a Wave customer has sufficient funds for a specific transaction.
                                    </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Box mb={2}>
                                    <Button variant="contained" size="small" color="primary" href="/confirmation-of-funds">
                                    Documentation
                                    </Button>
                                    </Box>
                                    {/* <Button size="small" color="primary">
                                    Learn More
                                    </Button> */}
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </React.Fragment>
        </div>
    )
}

export default Home
