import React, { Component } from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'; 
import SwaggerUI from 'swagger-ui';
import './App.css';
import Sidebar from './Sidebar.js'
import '../node_modules/swagger-ui/dist/swagger-ui.css'
import { experimentalStyled as styled } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuiDrawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// PAGES
import Home from './pages/Home';
import GetAccessToken from './pages/GetAccessToken';
import CustomerAuthorization from './pages/CustomerAuthorization';
import Registration from './pages/Registration';
import TestingInTheSandbox from './pages/TestingInTheSandbox';
import AccountAndTransactions from './pages/AccountAndTransactions';
import ConfirmationOfFunds from './pages/ConfirmationOfFunds';
import OAuth from './pages/OAuth';
import Support from './pages/Support';

function Copyright() {
  
  return (
    <Box>
      <Typography variant="body2" color="textSecondary" mt={4}>
        Wave is a trading name of Chetwood Financial Limited, company number 09964966. Chetwood Financial Limited is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority, under registration number 740551. Authorisation can be checked on the Financial Services Register at www.fca.org.uk. We’re also registered with the Information Commissioner’s Office under registration ZA218401 and we’re a member of CIFAS, the UK’s leading fraud prevention service. Chetwood Financial Limited is registered in England and Wales and our offices are at Ellice Way, Wrexham Technology Park, Wrexham, LL13 7YT.
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center" mt={2}>
        {' © '}
        Wave 2020. All rights reserved.
      </Typography>
    </Box>
  );
}
const drawerWidth = 320;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);



class App extends Component {


  constructor(props) {
    super(props);
    this.state = {
        organizationConfig: "",
        definitionList: "",
        definitionLink: "",
        open: true
      }
      this.swaggerhub = this.swaggerhub.bind(this)
      this.updateDefinitionLink = this.updateDefinitionLink.bind(this)
  }

  updateDefinitionLink(newLink) {
    this.setState({
      definitionLink: newLink
    })
    SwaggerUI({
      domNode: document.getElementById("api-data"),
      url: newLink
    })
  }

  render() {
    const open=this.state.open;
    const toggleDrawer = () => {
      this.setState({
        open: !open
      })
    };

    return (
      
      <div className="App">
       <Router>
       <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Open Banking
            </Typography>
            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <Typography
              component="h1"
              variant="h4"
              color="primary"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Wave<span className="jss9">.</span>
            </Typography>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List>
          <Sidebar />
        </List>
      </Drawer>
        <div>
        
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <div id="api-data">
                    <Switch>
                      <Route path="/" exact component={Home} />
                      <Route path="/registration" component={Registration}/>
                      <Route path="/get-access-token" component={GetAccessToken} />
                      <Route path="/customer-authorisation" component={CustomerAuthorization} />
                      <Route path="/testing-in-the-sandbox" component={TestingInTheSandbox} />
                      <Route path="/support" component={Support} />
                      <Route path="/account-and-transactions" component={AccountAndTransactions} />
                      <Route path="/confirmation-of-funds" component={ConfirmationOfFunds} />
                      <Route path="/oauth-api" component={OAuth} />
                      
                    </Switch>
                  </div>
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
        </div>
      </Box>
      </Router>
      </div>
    );
  }


  
  swaggerhub(inputMethod, inputResource, inputParams) {
    let url = ""
    if (inputParams) {
      url = "https://api.swaggerhub.com/apis/" + inputResource + "?" + inputParams
    } else {
      url = "https://api.swaggerhub.com/apis/" + inputResource
    }
    
    return fetch(url, {
        method: inputMethod
    }).then(response => {
      if (response.ok) {
        return response.json()
      } throw new Error('There was an issue requesting the API')
    }).then(json => {
      return json
    })
  }

}
export default App;
